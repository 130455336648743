import React from "react"
import Img from "gatsby-image"
import { animated, useSpring } from "react-spring"

const TechImage = props => {
  const calc = (localX, localY) => {
    let { x, y, height, width } =
      document.getElementById(props.id) &&
      document.getElementById(props.id).getBoundingClientRect()

    let scaleFactor = 1 / 3

    let differenceX = (localX - (x + width / 2)) * scaleFactor
    let differenceY = -(localY - (y + height / 2)) * scaleFactor

    return [differenceY, differenceX, 1.2]
  }

  const trans = (x, y, s) =>
    `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

  const [styleProps, set] = useSpring(() => ({
    xys: [0, 0, 1],
    config: { mass: 5, tension: 350, friction: 40 },
  }))

  const cardStyle = { transform: styleProps.xys.interpolate(trans) }

  return (
    <animated.div
      id={props.id}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{ ...styles.container, ...cardStyle }}
    >
      <Img
        fixed={typeof window === "undefined" ? { src: {} } : undefined}
        fluid={props.image}
      />
    </animated.div>
  )
}

const styles = {
  container: {
    width: "100%",
    height: "auto",
    maxHeight: 100,
    padding: 15,
    minHeight: 120,
    minWidth: 120,
  },
}

export default TechImage
