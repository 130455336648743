import React from "react"
import TechnologyImage from "../components/TechnologyImage"

export default class TechnologyImageGrid extends React.Component {
  render() {
    if (this.props.images) {
      return (
        <div className="leftAboutContainer" style={styles.leftContainer}>
          {this.props.images.map(image => (
            <TechnologyImage
              id={"techImage" + Math.random() * 100000000}
              image={image}
            />
          ))}
        </div>
      )
    } else {
      return <div />
    }
  }
}

const styles = {
  leftContainer: {
    width: "45%",
    display: "grid",
    gridTemplateColumns: "33% 33% 33%",
    gridTemplateRows: "33% 33% 33%",
  },
}
